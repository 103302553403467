import { useEffect, useState, createContext } from 'react';

import RequestService from 'services/RequestService';

const FeatureFlagContext = createContext();

export const FeatureFlagProvider = ({ featureFlags = {}, children }) => {
  const [adjustedFeatureFlags, setAdjustedFeatureFlags] = useState({
    ...featureFlags,
    consoleOpen: false,
  });

  useEffect(() => {
    const getFeatureFlags = async () => {
      const response = await RequestService.getFeatureFlags();
      if (response.ok && response.featureFlags) {
        setAdjustedFeatureFlags(state => ({ ...state, ...response.featureFlags }));
      }
    };

    getFeatureFlags();
  }, []);

  const context = {
    featureFlags: adjustedFeatureFlags,
    setAdjustedFeatureFlags,
  };

  return <FeatureFlagContext.Provider value={context}>{children}</FeatureFlagContext.Provider>;
};

FeatureFlagProvider.propTypes = {
  featureFlags: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default FeatureFlagContext;
